import React from "react";

let GlobalURL;
let LocalGlobalURL = [
  {
    url: "http://localhost/Australia/v2.1/php/",
    videourl: "http://localhost/Australia/v2.1/uploaded/videofile/",
    paypalid: "sb",
    paypaltype: "sandbox",
    version: "2.4.10002",
    storage: "localstorage", //localforage
  },
];

let LiveGlobalURL = [
  {
    url: "https://tfg.franchise.care/php/",
    videourl: "https://tfg.franchise.care/uploaded/videofile/",
    paypalid:
      "AZ1XjgwM4m-dg7L4OlQMf6jOdzq1H_6IDKLBVf7mh7VqIgJPsYSIKN03iC5LO2XtfSoLUac9NF5R0x8v",
    paypaltype: "live",
    version: "2.4.10002",
    storage: "localstorage", //localforage
  },
];

let StagingGlobalURL = [
  {
    url: "https://staging.franchise.care/php/",
    videourl: "https://staging.franchise.care/uploaded/videofile/",
    paypalid: "sb",
    paypaltype: "sandbox",
    version: "2.4.10002",
    storage: "localstorage", //localforage
  },
];

export default GlobalURL =
  window.location.hostname === "localhost"
    ? LocalGlobalURL
    : window.location.hostname === "staging.franchise.care"
    ? StagingGlobalURL
    : LiveGlobalURL;
